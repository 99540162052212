<template>
  <FormModal title="Type aanvraag">
    <template #body>
      <ServiceFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import ServiceFormStep from '~/components/form/steps/service/ServiceFormStep'

export default {
  components: {
    ServiceFormStep,
    FormModal
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data () {
    return {
      headTitle: 'Type opdracht',
      headDescription:
        'Geef aan naar wat voor type airconditioning je op zoek bent.',
      path: '/offertes-aanvragen/type-opdracht',
      progressValue: 33,
      checkoutStep: 1
    }
  }
}
</script>
